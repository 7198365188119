import React, { memo, useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    height: '480px',
    position: 'relative',
};

const Map = ({ children, center, zoom }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'ada10cdcd860b3f',
        googleMapsApiKey: 'AIzaSyAIMSoxb0xG1FpFIBu__r_gVnI6g3DDXCQ',
    });

    const [map, setMap] = useState(null);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
            options={{
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: false,
                draggable: false,
                mapId: 'ada10cdcd860b3f',
            }}
        >
            {children}
        </GoogleMap>
    ) : (
        <></>
    );
};

export default memo(Map);
