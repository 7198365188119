import React, { useEffect, useState } from 'react';
import {
    SContainerWrapper,
    SInfoBoxContainer,
    SInfoBoxContent,
} from './styles';
import Map from './map';
import { MarkerF } from '@react-google-maps/api';
// import MarkerIcon from '../../images/svg/marker.svg';
import { Row } from 'react-bootstrap';
import { useWindowSize } from '../../hooks/useWindowSize';


const locations = [
    {
        info:
            '<h3>Kaunas</h3>' +
            '<p><strong>Adresas:</strong> Taikos pr. 88A</p>' +
            '<p><strong>Tel:</strong> <a href="tel:+370 636 40444">+370 636 40444</a></p>' +
            '<p><strong>Email:</strong> info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.lt/maps/place/Taikos+pr.+88A,+Kaunas+51182/@54.9171135,23.9851736,17z/data=!3m1!4b1!4m5!3m4!1s0x46e71844c01926cf:0xa2937b6d3b4e5c5!8m2!3d54.9171135!4d23.9873623?hl=en" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 54.917113,
        long: 23.987362,
    },
    {
        info:
            '<h3>Klaipėda</h3>' +
            '<p>Adresas: H.Manto g. 7</p>' +
            '<p>Tel: <a href="tel:+370 619 85944">+370 619 85944</a></p>' +
            '<p>Email: info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.lt/maps/dir//H.+Manto+g.+7,+Klaip%C4%97da+92128/@55.71347,21.1280481,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x46e4dbe4de4e2c2d:0x14c2782261d26b8f!2m2!1d21.1302421!2d55.713467!3e0?hl=en" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 55.70728,
        long: 21.1379,
    },
    {
        info:
            '<h3>Šiauliai</h3>' +
            '<p>Adresas: Tilžės g. 122</p>' +
            '<p>Tel: <a href="tel:+370 612 92637">+370 612 92637</a></p>' +
            '<p>Email: info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.lt/maps/dir//Til%C5%BE%C4%97s+g.+122,+%C5%A0iauliai/@55.9281182,23.3054621,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x46e5e30327f1b9b5:0x9a2394b436d35a81!2m2!1d23.3098502!2d55.9281123!3e0?hl=en" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 55.932034,
        long: 23.310486,
    },
    {
        info:
            '<h3></h3>' +
            '<p><strong>Adresas:</strong> </p>' +
            '<p><strong>Tel:</strong> </p>' +
            '<p><strong>Email:</strong> </p>' +
            `<p class="gomap"><a href="" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 54.55241,
        long: 23.34828,
    },
    {
        info:
            '<h3>Vilnius</h3>' +
            '<p><strong>Adresas:</strong> Švitrigailos g. 11 M</p>' +
            '<p><strong>Tel:</strong> <a href="tel:+370 636 40444">+370 636 40444</a></p>' +
            '<p><strong>Email:</strong> info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.lt/maps/place/Švitrigailos+g.+11m,+Vilnius+03223/@54.6757497,25.264777,17z/data=!3m1!4b1!4m5!3m4!1s0x46dd946df38a5d4f:0x99b2af3ed42366d9!8m2!3d54.6757466!4d25.2669656?hl=en" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 54.67588,
        long: 25.267493,
    },
    {
        info:
            '<h3>Vilkaviškis</h3>' +
            '<p><strong>Adresas:</strong> Vytauto g. 24A</p>' +
            '<p><strong>Tel:</strong> <a href="tel:+370 623 52404">+370 623 52404</a></p>' +
            '<p><strong>Email:</strong> info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.com/maps/place/Vytauto+g.+24a,+Vilkavi%C5%A1kis+70124/@54.6484176,23.0338549,16.5z/data=!4m5!3m4!1s0x46e133902db063cf:0x2e419d824553a91!8m2!3d54.6485788!4d23.0339098" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 54.6485314,
        long: 23.034076,
    },
    {
        info:
            '<h3>Alytus</h3>' +
            '<p><strong>Adresas:</strong> Naujoji g. 3</p>' +
            '<p><strong>Tel:</strong> <a href="tel:+370 646 32010">+370 646 32010</a></p>' +
            '<p><strong>Email:</strong> info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.lt/maps/place/Naujoji+g.+3,+Alytus+63246/@54.3950944,24.0352988,17z/data=!3m1!4b1!4m5!3m4!1s0x46e0b14e57d110f7:0x3c5147a04fd1eb9!8m2!3d54.3950913!4d24.0374875?hl=en" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 54.3978845,
        long: 24.046927,
    },
    {
        info:
            '<h3>Telšiai</h3>' +
            '<p><strong>Adresas:</strong> Kęstučio g. 4</p>' +
            '<p><strong>Tel:</strong> <a href="tel:+370 600 59215">+370 600 59215</a></p>' +
            '<p><strong>Email:</strong> info@fsa.lt</p>' +
            `<p class="gomap"><a href="" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 55.984144,
        long: 22.249636,
    },
    {
        info:
            '<h3>Vilkaviškis</h3>' +
            '<p><strong>Adresas:</strong> Vytauto g. 24A</p>' +
            '<p><strong>Tel:</strong> <a href="tel:+370 623 52404">+370 623 52404</a></p>' +
            '<p><strong>Email:</strong> info@fsa.lt</p>' +
            `<p class="gomap"><a href="https://www.google.com/maps/place/Vytauto+g.+24a,+Vilkavi%C5%A1kis+70124/@54.6484176,23.0338549,16.5z/data=!4m5!3m4!1s0x46e133902db063cf:0x2e419d824553a91!8m2!3d54.6485788!4d23.0339098" target="_blank">Google žemėlapis <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12H18" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M12 5L19 12L12 19" stroke="#1E6980" stroke-width="1.5" stroke-linecap="square"/>
</svg></a></p>`,
        lat: 54.64869,
        long: 23.03374,
    },
];

const MapContainer = () => {
    const size = useWindowSize();

    const [zoom, setZoom] = useState(7); // initial zoom
    const [center, setCenter] = useState({
        lat: 55.239772,
        lng: 25.629369,
    });
    const [infoBoxContent, setInfoBoxContent] = useState(locations[0].info);

    const onMarkerClick = (index) => {
        setInfoBoxContent(locations[index].info);
    };

    useEffect(() => {
        if (size.width < 768) {
            setZoom(6);
            setCenter({
                lat: 54.239772,
                lng: 23.529369,
            });
        }
    }, [size]);

    return (
        <SContainerWrapper fluid>
            <Row style={{ position: 'relative' }}>
                <SInfoBoxContainer>
                    <SInfoBoxContent>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: infoBoxContent,
                            }}
                        />
                    </SInfoBoxContent>
                </SInfoBoxContainer>
                <Map center={center} zoom={zoom}>
                    {locations.map((l, i) => (
                        <MarkerF
                            onClick={() => onMarkerClick(i)}
                            position={{ lat: l.lat, lng: l.long }}
                            icon={'/marker.svg'}
                        />
                    ))}
                </Map>
            </Row>
        </SContainerWrapper>
    );
};

export default MapContainer;
