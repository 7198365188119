import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const SContainerWrapper = styled(Container)`
    position: relative;
    margin-bottom: 120px;
    padding: 0 25px;

    #map {
        height: 480px;
    }
`;

export const SInfoBoxContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0px;
    bottom: -70px;
    width: 390px !important;
    padding: 32px;
    border: 1px solid ${(props) => props.theme.color.grey_900};
    background-color: ${(props) => props.theme.color.white};

    @media (min-width: ${(props) => `${props.theme.breakpoints.landscape}px`}) {
        right: 30px;
    }
    @media (min-width: ${(props) => `${props.theme.breakpoints.laptop}px`}) {
        right: 50px;
    }
    @media (min-width: ${(props) => `${props.theme.breakpoints.desktop}px`}) {
        right: 105px;
    }
`;

export const SInfoBoxContent = styled.div`
    h3 {
        margin-bottom: 24px;
    }

    p {
        color: ${(props) => props.theme.color.grey_900};
        margin-bottom: 8px;
    }

    .gomap {
        margin-top: 40px;

        a {
            color: ${(props) => props.theme.color.primary};
            font-weight: ${(props) => props.theme.fontWeight.medium};
        }
    }
`;
export const SInfoBoxAddress = styled.span``;
export const SInfoBoxTel = styled.span``;
export const SInfoBoxEmail = styled.span``;
export const SInfoBoxMapLink = styled.a``;
