import React from 'react';
import { Row } from 'react-bootstrap';
import Button from '../../components/Button';
import ConsultationForm from '../../components/ConsultationForm';
import { StyledContainer } from '../Layout/styles';
import { STitle, SSubTitle, SCol, SContactDetails } from './styles';

const HeroContainer = () => {
    return (
        <StyledContainer fluid>
            <Row className="align-items-center">
                <SCol xs={12} lg={6} className="pe-lg-5">
                    <STitle>Visos finansinės paslaugos vienoje vietoje</STitle>
                    <SSubTitle>
                        Jau dabar ženkite pirmąjį žingsnį užtikrinto rytojaus
                        link – registruokitės nemokamai konsultacijai Jums
                        patogiu būdu arba susisiekite su mumis žemiau nurodytais
                        kontaktais.
                    </SSubTitle>
                    <Button
                        bgColor="primary"
                        onClick={(e) => {
                            window.location.href = 'mailto:info@fsa.lt';
                            e.preventDefault();
                        }}
                    >
                        Susisiekite su mumis
                    </Button>
                    <SContactDetails>
                        <div>
                            <strong>Tel:</strong>{' '}
                            <a href="tel:+37063640444">+370 636 40444</a>
                        </div>
                        <div>
                            <strong>El. paštas:</strong>{' '}
                            <a href="mailto:info@fsa.lt">info@fsa.lt</a>
                        </div>
                    </SContactDetails>
                </SCol>
                <SCol xs={12} lg={6} className="mt-4 mt-lg-0">
                    <ConsultationForm />
                </SCol>
            </Row>
        </StyledContainer>
    );
};

export default HeroContainer;
