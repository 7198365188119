import * as React from 'react';
import HeroContainer from '../containers/HeroContainer';
import MapContainer from '../containers/MapContainer';
import NewsContainer from '../containers/NewsContainer';
import PaslaugosContainer from '../containers/PaslaugosContainer';

const IndexPage = ({ pageContext }) => {
    return (
        <>
            <HeroContainer />
            <PaslaugosContainer data={pageContext.recentPaslaugos} />
            <NewsContainer blogPosts={pageContext.recentPosts} isHomePage={true} />
            <MapContainer />
        </>
    );
};

export default IndexPage;
