import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const SCol = styled(Col)`
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const STitle = styled.h1`
    color: ${(props) => props.theme.color.grey_900};
    font-size: ${(props) => props.theme.fontSize.title};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    line-height: ${(props) => props.theme.lineHeight.title};

    @media (max-width: 768px) {
        font-size: ${(props) => props.theme.fontSize.h2};
        line-height: ${(props) => props.theme.lineHeight.medium};
    }
`;

export const SSubTitle = styled.span`
    color: ${(props) => props.theme.color.grey_400};
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
    margin-bottom: 64px;
    margin-top: 24px;

    @media (max-width: 768px) {
        margin-bottom: 24px;
        margin-top: 12px;
    }
`;

export const SContactDetails = styled.div`
    display: flex;
    margin-top: 64px;

    div:not(:last-child) {
        margin-right: 40px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 24px;

        div:not(:last-child) {
            margin-bottom: 20px;
            margin-right: 0;
        }
    }
`;
